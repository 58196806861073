import { motion } from 'framer-motion'

export default function Index() {
	// const user = useOptionalUser();
	return (
		<div className="overflow-hidden">
			<div className="relative z-10 h-screen w-screen bg-black bg-cover bg-center">
				<div className="video-cta absolute inset-0 opacity-50">
					<video
						src="/images/bg-video.mp4"
						autoPlay
						muted
						loop
						playsInline
						className="h-full w-full object-cover"
					/>
				</div>
				<div className="z-10 flex h-full w-full">
					<div className="item-center flex w-full flex-col justify-center">
						<div className="z-40 flex w-full flex-col items-center justify-center">
							<motion.div
								className="mx-auto text-3xl uppercase tracking-wide text-yellow-500"
								initial={{ opacity: 0 }}
								whileInView={{ opacity: 1, scale: 1 }}
								transition={{
									duration: 0.8,
									delay: 0.2,
									type: 'easeInOut',
								}}
							>
								<div className="flex flex-col items-center">
									<img
										className="h-24 w-auto md:h-48"
										src="/images/sdda-logo-without-circle-white.png"
										alt="Your Company"
									/>
									<div className="mt-4 text-xl text-neutral-200">
										Inspiring the Next generation
									</div>
								</div>
							</motion.div>
						</div>
					</div>
				</div>
			</div>
			{/* <div className="relative h-screen">
        <div className="absolute inset-0">
          <img
            src="/images/home-hero.jpg"
            alt="background"
            className="h-full w-full object-cover"
          />
        </div>
        <div className="absolute inset-0 bg-gradient-to-b from-stone-900 opacity-75" />
        <div className="absolute inset-x-[-70vw] bottom-36">
          <div className="container mx-auto flex h-full max-w-lg">
            <div className="item-center z-40 flex w-full flex-col justify-center">
              <motion.div
                className="text-center text-3xl uppercase tracking-tight text-stone-900"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{
                  duration: 0.8,
                  delay: 0.2,
                  type: "easeInOut",
                }}
              >
                <div className="rounded-lg bg-stone-100 p-2">
                  Inspiring the Next generation
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div> */}
			<main>
				<div className="relative isolate overflow-hidden bg-stone-900">
					<div className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl">
						<svg
							viewBox="0 0 1313 771"
							aria-hidden="true"
							className="ml-[max(50%,38rem)] w-[82.0625rem]"
						>
							<path
								id="bc169a03-3518-42d4-ab1e-d3eadac65edc"
								fill="url(#85a0eca5-25f1-4ab9-af84-4e2d8d9cdbf3)"
								d="M360.508 589.796 231.671 770.522 0 498.159l360.508 91.637 232.034-325.485c1.485 150.396 51.235 393.965 238.354 165.069C1064.79 143.261 1002.42-107.094 1171.72 46.97c135.44 123.252 148.51 335.641 138.11 426.428L971.677 339.803l24.062 411.461-635.231-161.468Z"
							/>
							<defs>
								<linearGradient
									id="85a0eca5-25f1-4ab9-af84-4e2d8d9cdbf3"
									x1="1313.17"
									x2="-88.881"
									y1=".201"
									y2="539.417"
									gradientUnits="userSpaceOnUse"
								>
									<stop stopColor="#9089FC" />
									<stop offset={1} stopColor="#FF80B5" />
								</linearGradient>
							</defs>
						</svg>
					</div>
					<div className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end">
						<svg
							viewBox="0 0 1313 771"
							aria-hidden="true"
							className="ml-[-22rem] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] xl:ml-0 xl:mr-[calc(50%-12rem)]"
						>
							<use href="#bc169a03-3518-42d4-ab1e-d3eadac65edc" />
						</svg>
					</div>
					<div className="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
						<div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8">
							{/* <img
								className="mt-4 h-24 w-auto md:mt-24 md:h-36"
								src="/images/logo-white.png"
								alt="Your Company"
							/> */}
							<h3 className="mt-6 text-3xl font-bold uppercase tracking-widest text-white sm:text-6xl">
								<span className="text-[#f9c04d]">Be</span>Inspired
								<span className="text-[#f9c04d]">.</span>
							</h3>
							<h3 className="mt-1 text-3xl font-bold uppercase tracking-widest text-white sm:text-6xl">
								<span className="text-[#f9c04d]">Be</span>Energized
								<span className="text-[#f9c04d]">.</span>
							</h3>
							<h3 className="mt-1 text-3xl font-bold uppercase tracking-widest text-white sm:text-6xl">
								<span className="text-[#f9c04d]">Be</span>Extraordinary
								<span className="text-[#f9c04d]">.</span>
							</h3>
							<p className="mt-6 leading-8 text-gray-100 sm:text-lg">
								Our studio is a space for inspiring the next generation. We work
								to make each dancer feel a part of our Stage Door family and our
								goal is to make a positive impact on all who share in our dance
								community.
							</p>
							<p className="mt-6 leading-8 text-gray-100 sm:text-lg">
								We would love to have you be a part of our 33rd season of dance
								excellence! Interested in being a part of the fun? Come see the
								magic within and find the perfect class for your dancer today!
							</p>
							<div className="mt-10 flex items-center gap-x-6">
								<a
									href="https://docs.google.com/forms/d/e/1FAIpQLSfTuUaMX2hJsdzAm4cDISNFoKhchbC751csuPG86WQlvgbcRw/viewform"
									target="_blank"
									rel="noreferrer"
								>
									<button className="rounded-md bg-[#f9c04d] px-3.5 py-2.5 text-base font-bold text-zinc-900 shadow-sm hover:opacity-90 focus:outline-none">
										Get Your FREE Class Today!
									</button>
								</a>
							</div>
						</div>
						<div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
							<div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
								<div className="-m-2 rounded-xl p-2 lg:-m-4 lg:rounded-2xl lg:p-4">
									<img
										src="/images/home-hero.jpg"
										alt="Studio Students"
										width={2432}
										height={1442}
										className="w-[76rem] rounded-md shadow-2xl ring-1 ring-gray-900/10"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="h-1 bg-stone-800" />
				<div className="relative isolate overflow-hidden bg-stone-900 pb-12">
					<div className="mx-auto max-w-7xl px-6 py-24 lg:px-8">
						<div className="md:grid md:grid-cols-6 md:items-start md:justify-start md:space-x-8">
							<div className="text-5xl font-extrabold text-stone-50 sm:text-6xl md:col-span-2">
								A LOOK INSIDE<span className="text-[#f9c04d]">.</span>
							</div>
							<div className="mt-6 space-y-4 text-lg font-extrabold tracking-wide text-stone-200 md:col-span-4 md:mt-0 md:text-2xl">
								<p>
									Our goal is to provide the highest quality dance education by
									sustaining a nurturing and positive atmosphere wherein young
									students may grow and learn.
								</p>
								<p className="text-base">
									<span className="text-[#f9c04d]">
										Stage Door Dance Academy
									</span>{' '}
									opened its doors in 1992 in Palos Park, Illinois. The entrance
									was in the back of the building emulating a theater’s “stage
									door” and that’s how we took the name “Stage Door Dance
									Academy.” In 2005, we relocated to our present location in Oak
									Forest. We look forward to celebrating our{' '}
									<span className="text-[#f9c04d]">33rd season</span> of dance
									with you!
								</p>
							</div>
						</div>
					</div>
					<div className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl">
						<svg
							viewBox="0 0 1313 771"
							aria-hidden="true"
							className="ml-[max(50%,38rem)] w-[82.0625rem]"
						>
							<path
								id="bc169a03-3518-42d4-ab1e-d3eadac65edc"
								fill="url(#85a0eca5-25f1-4ab9-af84-4e2d8d9cdbf3)"
								d="M360.508 589.796 231.671 770.522 0 498.159l360.508 91.637 232.034-325.485c1.485 150.396 51.235 393.965 238.354 165.069C1064.79 143.261 1002.42-107.094 1171.72 46.97c135.44 123.252 148.51 335.641 138.11 426.428L971.677 339.803l24.062 411.461-635.231-161.468Z"
							/>
							<defs>
								<linearGradient
									id="85a0eca5-25f1-4ab9-af84-4e2d8d9cdbf3"
									x1="1313.17"
									x2="-88.881"
									y1=".201"
									y2="539.417"
									gradientUnits="userSpaceOnUse"
								>
									<stop stopColor="#9089FC" />
									<stop offset={1} stopColor="#FF80B5" />
								</linearGradient>
							</defs>
						</svg>
					</div>
					<div className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end">
						<svg
							viewBox="0 0 1313 771"
							aria-hidden="true"
							className="ml-[-22rem] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] xl:ml-0 xl:mr-[calc(50%-12rem)]"
						>
							<use href="#bc169a03-3518-42d4-ab1e-d3eadac65edc" />
						</svg>
					</div>
					<div className="mx-auto max-w-7xl px-6 lg:px-8">
						<div className="mx-auto mt-2 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900 sm:mt-2 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
							<figure className="col-span-2 hidden sm:block sm:rounded-2xl sm:bg-stone-100 sm:shadow-lg sm:ring-1 sm:ring-gray-900/5 xl:col-start-2 xl:row-end-1">
								<blockquote className="p-12 text-xl font-semibold leading-8 tracking-tight text-gray-900">
									<p>{`“${featuredTestimonial.body}”`}</p>
								</blockquote>
								<figcaption className="flex items-center gap-x-4 border-t border-gray-900/10 px-6 py-4">
									<div className="flex-auto">
										<div className="font-semibold">
											{featuredTestimonial.author.name}
										</div>
									</div>
								</figcaption>
							</figure>
							{testimonials.map((columnGroup, columnGroupIdx) => (
								<div
									key={columnGroupIdx}
									className="space-y-8 xl:contents xl:space-y-0"
								>
									{columnGroup.map((column, columnIdx) => (
										<div
											key={columnIdx}
											className={classNames(
												(columnGroupIdx === 0 && columnIdx === 0) ||
													(columnGroupIdx === testimonials.length - 1 &&
														columnIdx === columnGroup.length - 1)
													? 'xl:row-span-2'
													: 'xl:row-start-1',
												'space-y-8',
											)}
										>
											{column.map((testimonial) => (
												<figure
													key={testimonial.author.handle}
													className="rounded-2xl bg-stone-100 p-6 shadow-lg ring-1 ring-gray-900/5"
												>
													<blockquote className="text-gray-900">
														<p>{`“${testimonial.body}”`}</p>
													</blockquote>
													<figcaption className="mt-6 flex items-center gap-x-4">
														<div>
															<div className="font-semibold">
																{testimonial.author.name}
															</div>
														</div>
													</figcaption>
												</figure>
											))}
										</div>
									))}
								</div>
							))}
						</div>
					</div>
				</div>
				<div className="overflow-hidden bg-stone-900 pb-24">
					<div className="mx-auto max-w-7xl px-6 py-24 lg:px-8">
						<dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
							{stats.map((stat) => (
								<div
									key={stat.id}
									className="mx-auto flex max-w-xs flex-col gap-y-4"
								>
									<dt className="text-base leading-7 text-stone-50">
										{stat.name}
									</dt>
									<dd className="order-first text-3xl font-semibold tracking-tight text-stone-50 sm:text-5xl">
										{stat.value}
									</dd>
								</div>
							))}
						</dl>
					</div>
				</div>
			</main>
		</div>
	)
}

const stats = [
	{ id: 1, name: 'Opened our doors in Palos Park, IL', value: '1992' },
	{
		id: 2,
		name: 'Relocated to our present location in Oak Forest, IL',
		value: '2005',
	},
	{
		id: 3,
		name: 'Honored to celebrate so many years of dance with you',
		value: '33rd Season',
	},
]

const featuredTestimonial = {
	body: 'In short, Stage Door has changed my life. From the moment I started taking class at age 3, I was instantly welcomed into the Stage Door family. Over my 15 years at Stage Door, I received a top notch dance education from some of the most qualified teachers in the business that prepared me for college dance and beyond. But looking back, I received so much more. At Stage Door, hard work, determination and above all kindness and compassion for others will always be more important than how high you can kick or how many awards you win. I am not only a better dancer, but most certainly a better person for having walked through the doors of Stage Door Dance Academy.',
	author: {
		name: 'Clare McIntyre',
		handle: 'clare',
	},
}
const testimonials = [
	[
		[
			{
				body: 'One of the best decisions as parents we have made was enrolling our girls at Stage Door! Not only have they become beautiful dancers but amazing well-rounded young women who have learned invaluable life lessons and found a second home and extended family.  Debbie, Kate and the rest of the faculty are incredible teachers and wonderful mentors! They care about each dancer as if they are their own and create a loving supportive environment for the dancers to flourish.  Bravo Stage Door, you have set the “dance bar” high!',
				author: {
					name: 'Jim & Lisa Barclay',
					handle: 'jim',
				},
			},
			{
				body: 'We have been a part of the Stage Door family for 3 years and have loved every minute of it!!',
				author: {
					name: 'Jennifer Grove',
					handle: 'jennifer',
				},
			},
			{
				body: 'I have had the privilege of dancing at Stage Door Dance Academy for eight years, and now have the privilege of being a faculty member. This studio has never failed to feel like home, and is constantly full of love, support, teamwork, and comfort. This studio is unlike any other and has been a major support system and great influence in my life and many others! I am so proud I get to be a part of Stage Door, it is so much more than just a dance studio!',
				author: {
					name: 'Abbey Barclay',
					handle: 'abbey',
				},
			},
		],
		[
			{
				body: 'I started with Ms. Debbie at the age of 2 and danced at Stage Door my entire childhood.  Ms. Debbie and the staff make each student feel important and build up their confidence. I know my confidence level wouldn’t be where it is today without them.  Many life lessons that I use  in day to day life I learned through the years I spent here.  Stage Door, Ms. Debbie, and the rest of the teachers were my second family growing up and I cherish those days and am so appreciative for everything they have taught me over the years.  I can only hope to be that influential  on others as this dance family was to me.',
				author: {
					name: 'Alyssa Solofra',
					handle: 'alyssa',
				},
			},
			// More testimonials...
		],
	],
	[
		[
			{
				body: 'Stage Door has been our home for the last past 8 plus years. My kids love every single minute at the studio. We thank every single teacher for all your hard work and dedication to all our kids.',
				author: {
					name: 'Yorlyn Salas',
					handle: 'yorlyn',
				},
			},
			{
				body: 'The positive influence of the staff at Stage Door Dance Academy lasts forever and I will forever be grateful for their influence on my daughter, Jessica.',
				author: {
					name: 'Breeda Carr',
					handle: 'breedacarr',
				},
			},
			// More testimonials...
		],
		[
			{
				body: 'I am a fellow teacher from Windsor, Ontario, Canada that has had the privilege to meet Miss Kate and some of her wonderful teachers and students over the past few years. It is so rewarding to make new friends in the dance community not only among teachers but our students have become friends from across the miles as well. We always look forward to attending competitions and seeing how beautifully our dancers perform, but more impressively how much they respect each other and cheer each other on.',
				author: {
					name: 'Cindy Pattison',
					handle: 'cindy',
				},
			},
			{
				body: 'Stage Door Dance Academy has the most inviting and accepting atmosphere. From the moment you step in the door, you’re overwhelmed with an abundance of love. Dance family is like no other and I’m so proud to call Stage Door mine. With the help of Stage Door I’ve truly been changed for the better.',
				author: {
					name: 'Jai Hendricks',
					handle: 'jai',
				},
			},
			{
				body: 'My daughter has been dancing here for the past 8 years and she loves it. The teachers are amazing not only in teaching dance but by providing a nurturing, loving environment and creating a second home for the children.',
				author: {
					name: 'Georgia Jerantowski',
					handle: 'georgia',
				},
			},
		],
	],
]

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ')
}
